@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@angular/cdk/overlay-prebuilt.css";
@import "./nhq-mixins.scss";
@import "./material-override.scss";

@layer base {
    html {
        font-family: "Inter", system-ui, sans-serif;
    }

    html,
    body {
        //height: 100vh;
        //width: 100vw;
        overflow: auto;
        margin: 0;
        padding: 0;
    }

}

@layer components {
    .account-container {
        max-width: 512px;
        margin-right: auto;
        margin-left: auto;
    }
}

@layer utilities {
    .bg-gradient-angle-0 {
        --gradient-angle: 0deg;
    }

    .bg-gradient-angle-90 {
        --gradient-angle: 90deg;
    }

    .bg-gradient-angle-120 {
        --gradient-angle: 120deg;
    }

    .bg-lime-to-yellow {
        background: linear-gradient(var(--gradient-angle, 118deg), #B9FA4F 28.65%, #FCFF68 91.67%);
    }

    .bg-red-gradient {
        background: linear-gradient(var(--gradient-angle, 118deg), #fe60e5 0%, #f00 80.98%, #f00 99.92%);
    }

    .bg-green-gradient {
        background: linear-gradient(var(--gradient-angle, 118deg), #b9fa4f 28.65%, #fcff68 91.67%);
    }

    .bg-blue-gradient {
        background: linear-gradient(var(--gradient-angle, 118deg), #ec95ff 28.12%, #0047ff 93.23%);
    }

    .bg-lime-gradient {
        background: linear-gradient(var(--gradient-angle, 180deg), rgba(185, 250, 75, 5.54), rgba(185, 250, 75, 0));
    }

    .bg-pink-gradient {
        background: linear-gradient(var(--gradient-angle, 180deg), rgba(246, 204, 255, 5.54), rgba(246, 204, 255, 0));
    }

    .bg-pink-red-gradient {
        background: linear-gradient(118deg, #FE60E5 0%, #F00 80.98%, #F00 99.92%);
    }

    .bg-pink-gradient-2 {
        background: linear-gradient(118deg, #F6CCFF 28.65%, #F9E7FD 91.67%);
    }
}


// ==============================================================

:root {}

.material-icons {
    font-family: "Material Icons";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
}

.cdk-global-scrollblock {
    overflow-y: hidden;
    position: unset;
}


.openBankingModal {
    width: 90vw;
    max-width: unset !important;

    .mat-mdc-dialog-surface {
        border-radius: 20px !important;
        box-shadow: 0px 4px 4px 0px #00000040 !important;
    }
}

.emoji {
    font-family: "Twemoji Mozilla", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        "Noto Color Emoji", "EmojiOne Color", "Android Emoji", sans-serif !important;
    font-weight: normal !important;
    display: inline-block;
}
